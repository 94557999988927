import "twin.macro"
import { Link } from "gatsby"
import React from "react"
import tw from "twin.macro"

const CalendarItem = ({
    id,
    color,
    duration,
    empty = false,
    organizer,
    scheduleend,
    schedulestart,
    start,
    title,
    uri,
    tag,
    venue,
}) => {

  return (
    <>
      {!empty && (
        <Link to={uri} tw="">
          <div
            css={[
              tw`absolute w-full`,

              start == 0 && duration == 15 && tw`h-[50px] top-[0px]`,
              start == 0 && duration == 30 && tw`h-[72px] top-[0px]`,
              start == 0 && duration == 45 && tw`h-[108px] top-[0px]`,
              start == 0 && duration == 60 && tw`h-[144px] top-[0px]`,
              start == 0 && duration == 90 && tw`h-[226px] top-[0px]`,

              start == 5 && duration == 15 && tw`h-[50px] top-[12px]`,
              start == 5 && duration == 30 && tw`h-[72px] top-[12px]`,
              start == 5 && duration == 45 && tw`h-[108px] top-[12px]`,
              start == 5 && duration == 60 && tw`h-[144px] top-[12px]`,
              start == 5 && duration == 90 && tw`h-[226px] top-[12px]`,

              start == 15 && duration == 15 && tw`h-[50px] top-[38px]`,
              start == 15 && duration == 30 && tw`h-[72px] top-[38px]`,
              start == 15 && duration == 45 && tw`h-[108px] top-[38px]`,
              start == 15 && duration == 60 && tw`h-[144px] top-[38px]`,
              start == 15 && duration == 90 && tw`h-[226px] top-[38px]`,

              start == 30 && duration == 15 && tw`h-[50px] top-[70px]`,
              start == 30 && duration == 30 && tw`h-[72px] top-[70px]`,
              start == 30 && duration == 45 && tw`h-[108px] top-[70px]`,
              start == 30 && duration == 60 && tw`h-[144px] top-[70px]`,
              start == 30 && duration == 90 && tw`h-[226px] top-[70px]`,

              start == 45 && duration == 15 && tw`h-[50px] top-[95px]`,
              start == 45 && duration == 30 && tw`h-[72px] top-[108px]`,
              start == 45 && duration == 45 && tw`h-[108px] top-[108px]`,
              start == 45 && duration == 60 && tw`h-[144px] top-[108px]`,
              start == 45 && duration == 90 && tw`h-[226px] top-[108px]`,

              tag?.name == 'Panels und Workshops' && tw`bg-event-95`,
              tag?.name == 'Formate der Bundesstiftung Gleichstellung' && tw`bg-event-28`,
              tag?.name == 'Kurze Formate' && tw`bg-event-666`,

              tag?.name == undefined && tw`bg-[#C7B5FF]`,
            ]}
          >
            <div tw="p-2 text-left top-0 left-0" css={[id === 'cG9zdDoxNjk0' ? tw`[border-bottom: 1px solid #FFFFFF]` : tw``]}>
              <div tw="text-sm font-bold">{title}</div>
              {id != 'cG9zdDoxNjk0' && (
                <div>{schedulestart}–{scheduleend} Uhr</div>
              )}
            </div>
            {duration > 15 && (
              <div tw="absolute p-2 text-left bottom-0 left-0">
                <div tw="text-sm font-bold">{organizer}</div>
              </div>
            )}
          </div>
        </Link>
      )}
    </>
  )
}

export default CalendarItem
